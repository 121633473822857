<template>
    <div class="course-list-layout">
        <table-th wh="121px">
            <div class="class-patrol">
                
            </div>
        </table-th>
        <course-list-item
            v-for="(item, index) of courseList"
            :key="index"
            :data="item"
            :type="type"
        >
        </course-list-item>
    </div>
</template>
<script>
    import TableTh from './Sub/TableTh.vue';
    import courseListItem from './courseListItem.vue';
    export default {
        name: "CourseList",
        components: {
            TableTh,
            courseListItem
        },
        props: {
            queryList: Object,
            courseList: Array,
            isTourClass: String,
            type:String
        },
        computed: {
            classPatrol_off () {
                return require('@/assets/images/classPatrol_off.png')
            },
            classPatrol_on () {
                return require('@/assets/images/classPatrol_on.png')
            }
        },
        methods: {
        }
    }
</script>

<style lang="scss" scoped>
    .course-list-layout {
        background-color: #2288F1;
       
    }
</style>