<template>
    <div class="day-list-layout">
        <div class="day-list-item">
            <div
                class="item"
                v-if="data.subjectName"
                @click="detailDay"
            >
                <div class="item-left">
                    <el-tooltip
                        effect="dark"
                        :content="data.subjectName"
                        placement="top-start"
                    >
                        <span class="subjectname">
                            {{ data.subjectName }}
                        </span>
                    </el-tooltip>
                    <el-tooltip
                        effect="dark"
                        :content="data.teacherName"
                        placement="top-start"
                    >
                        <span class="teachername">
                            ({{ data.teacherName }})
                        </span>
                    </el-tooltip>
                    <div class="day-info">
                        <div class="classcenter">
                            <el-tooltip
                                effect="dark"
                                :content="data.className"
                                placement="top-start"
                            >
                                <div class="classname">
                                    {{ data.className }}
                                </div>
                            </el-tooltip>

                            <span style="padding: 0px 8px">|</span>
                            <span class="repeat">{{
                                data.repeatClass | repeatClassName
                            }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else
                class="add-course-schedule"
            >
                <span class="add"></span>
            </div>
        </div>
    </div>
</template>
<script>
import TeachingResearchCourseScheduleTempFilter from "@/filters/TeachingResearchCourseScheduleTemp.js";
export default {
    name: "DayListItem",
    filters: TeachingResearchCourseScheduleTempFilter,
    props: {
        data: Object,
        daylist: Array,
        isTourClass: String,
        idx: Number,
        courseList: Array,
        type: String,
        courseScheduleDetailSubjectsList: Array,
        courseScheduleDetailTeachersList: Array,
        queryList: Object,
    },
    data() {
        return {
            operateShow: false,
        };
    },
    computed: {
        prohibitAdding() {
            return require("@/assets/images/prohibit-adding.png");
        },
    },
    methods: {
        showError(time) {
            return (time._timestamp = new Date().getTime());
        },
        detailDay() {
            let detailDayData = {
                data: this.data,
                daylist: this.daylist[0],
                idx: this.idx,
            };
            this.$eventDispatch("detailDay", detailDayData);
            // this.$eventDispatch('detailDay', this.data,this.daylist,this.idx)
        },
        getSubjectsName(list, subjectName) {
            list.forEach((item) => {
                if (this.data.subjectId == item.id) {
                    subjectName.push(item.name);
                }

                if (item.children && item.children.length > 0) {
                    this.getSubjectsName(item.children, teachName);
                }
            });
        },
        getTeacherName(list, teachName) {
            list.forEach((item) => {
                if (this.data.classTeacherId == item.id) {
                    teachName.push(item.name);
                }

                if (item.children && item.children.length > 0) {
                    this.getTeacherName(item.children, teachName);
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.day-list-layout:hover {
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    position: relative;
    border-radius: 2px;
    transform: scale(1.001);
}
.day-list-layout {
    box-sizing: border-box;
    .day-list-item {
        border-bottom: 1px solid #61afff;
        .item {
            height: 105px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #fff;
            .item-left {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .subjectname {
                    max-width: 100px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    color: #363b40;
                    font-size: 20px;
                    margin-left: 30px;
                }
                .teachername {
                    max-width: 100px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    color: #6d7073;
                    font-size: 14px;
                    margin-left: 10px;
                }
                .day-info {
                    margin-left: 120px;
                    color: #6d7073;
                    font-size: 14px;
                    // background-color: rebeccapurple;
                    .classcenter {
                        display: flex;
                        justify-content: flex-start;;
                        align-items: center;
                        .class-patrol {
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #499df2;
                        }
                        .classname {
                            max-width: 220px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
            .option-list {
                color: #e63a2e;
                font-size: 14px;
                .edit {
                    color: #3c7fff;
                    margin-right: 48px;
                }
                .delAllCourses {
                    margin-right: 48px;
                }
            }
            .check-state {
                margin-left: 30px;
                img {
                    height: 16px;
                    width: 16px;
                }
            }
        }
        .add-course-schedule {
            height: 105px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .el-icon-plus {
                color: #c8cacc;
            }

            .add {
                width: 14px;
                height: 2px;
                background: #c8cacc;
                border-radius: 1px;
            }
        }

        .add-course-schedule:hover {
            .el-icon-plus {
                color: #499df2;
            }
            .add {
                color: #499df2;
            }
        }
    }
}
@media screen and (max-width: 1440px) {
    .day-list-layout {
        box-sizing: border-box;
        .day-list-item {
            border-bottom: 1px solid #61afff;
            .item {
                height: 79px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #fff;
                .item-left {
                    display: flex;
                    justify-content: flex-start;;
                    align-items: center;
                    .subjectname {
                        max-width: 100px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        color: #363b40;
                        font-size: 18px;
                        margin-left: 30px;
                    }
                    .teachername {
                        max-width: 100px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        color: #6d7073;
                        font-size: 14px;
                        margin-left: 10px;
                    }
                    .day-info {
                        margin-left: 120px;
                        color: #6d7073;
                        font-size: 14px;
                        // background-color: rebeccapurple;
                        .classcenter {
                            display: flex;
                            justify-content: flex-start;;
                            align-items: center;
                            .class-patrol {
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #499df2;
                            }
                            .classname {
                                max-width: 220px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }
                .option-list {
                    color: #e63a2e;
                    font-size: 14px;
                    .edit {
                        color: #3c7fff;
                        margin-right: 48px;
                    }
                    .delAllCourses {
                        margin-right: 48px;
                    }
                }
                .check-state {
                    margin-left: 30px;
                    img {
                        height: 16px;
                        width: 16px;
                    }
                }
            }
            .add-course-schedule {
                height: 79px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                .el-icon-plus {
                    color: #c8cacc;
                }

                .add {
                    width: 14px;
                    height: 2px;
                    background: #c8cacc;
                    border-radius: 1px;
                }
            }

            .add-course-schedule:hover {
                .el-icon-plus {
                    color: #499df2;
                }
                .add {
                    color: #499df2;
                }
            }
        }
    }
}
</style>
