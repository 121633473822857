<template>
    <div class="day-list">
        <template v-if="this.dayList[0]">
            <table-th wh="100%">
                <div class="day-title">
                    <div class="day-label">
                        {{ this.dayList[0].label }}
                    </div>
                </div>
            </table-th>
            <div v-if="tableData && tableData.length > 0">
                <day-list-item
                    class="day-list-item"
                    :class="index % 2 == 1 ? 'active' : ''"
                    v-for="(item, index) of this.dayList[0].child"
                    :key="index"
                    :data="item"
                    :daylist="dayList"
                    :idx="index"
                    :isTourClass="isTourClass"
                    :courseList="courseList"
                    :tableData="tableData"
                    :type="type"
                    :courseScheduleDetailSubjectsList="
                        courseScheduleDetailSubjectsList
                    "
                    :courseScheduleDetailTeachersList="
                        courseScheduleDetailTeachersList
                    "
                    :queryList="queryList"
                    @changeCheck="changeCheck(item)"
                >
                </day-list-item>
            </div>

            <div
                class="no_content"
                v-else
            >
                <div class="no_img">
                    <img
                        :src="noContentMyCurriculum"
                        alt="暂无排课~"
                    />
                </div>
                <div class="no_title">暂无排课~</div>
            </div>
        </template>
    </div>
</template>
<script>
import TableTh from "./Sub/TableTh.vue";
import DayListItem from "./DayListItem.vue";
export default {
    name: "DayList",
    components: {
        TableTh,
        DayListItem,
    },
    props: {
        dayList: {
            type: Array,
            default: function () {
                return [
                    {
                        label: "",
                        child: [],
                    },
                ];
            },
        },
        isTourClass: String,
        tableData: Array,
        courseList: Array,
        type: String,
        courseScheduleDetailSubjectsList: Array,
        courseScheduleDetailTeachersList: Array,
        queryList: Object,
    },
    computed: {
        noContentMyCurriculum() {
            return require("@/assets/images/my-curriculum.png");
        },
    },
    mounted() {
        console.log("日tableData", this.tableData);
    },
    methods: {
        changeCheck(item) {
            this.$set(item, "checkState", !item.checkState);
            this.$eventDispatch("changeCheck", {item: item});
        },
    },
};
</script>

<style lang="scss" scoped>
.day-list {
    flex: 1;
    .no_content {
        height: calc(100% - 46px);
        padding-top: 40%;
        transform: translateY(-50%);
        box-sizing: border-box;
        .no_img {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .no_title {
            text-align: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
        }
    }
    .day-list-item.active {
        background-color: #edf4fa;
    }
    .day-title {
        width: 100%;
        box-sizing: border-box;
        padding: 0 51px 0 32px;
        display: flex;
        justify-content: space-between;
        .day-label {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            // color: #808487;
            color: #ffffff;
        }
        .option {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #f56c6c;
            cursor: pointer;
        }
    }
}
</style>
